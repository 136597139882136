import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
var firebaseConfig = {
  apiKey: "AIzaSyBUNPiTXi34DGLbCK0tuZz23LSwK08e7qE",
  authDomain: "crack-audio-252816.firebaseapp.com",
  databaseURL: "https://crack-audio-252816.firebaseio.com",
  projectId: "crack-audio-252816",
  storageBucket: "crack-audio-252816.appspot.com",
  messagingSenderId: "471911833685",
  appId: "1:471911833685:web:c66fe0a9843fe340fb34dc",
  measurementId: "G-ZHEHXJD8ZR",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
