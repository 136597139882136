import React, { Component } from "react";
import uuid from "uuid/v4";

export default class Question extends Component {
  state = {
    isMeaningVisible: false,
    isMarked: this.props.word.done,
  };
  camelCase = (str) => {
    if (str == null) {
      return "";
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  render() {
    let { word } = this.props;
    return (
      <div key={uuid()}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
            alignItems: "center",
          }}
        >
          <h6>{this.camelCase(word.word)}</h6>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              onClick={() =>
                this.setState((prevState) => ({
                  isMeaningVisible: !prevState.isMeaningVisible,
                }))
              }
              style={{ marginRight: 20, width: 70 }}
              className={
                this.state.isMeaningVisible ? "btn btn-warning" : "btn btn-info"
              }
            >
              {this.state.isMeaningVisible ? "Hide" : "Show"}
            </button>
            <button
              onClick={() => {
                this.props.setMarked(word);
              }}
              style={{ width: 100 }}
              className={word.done ? "btn btn-success" : "btn btn-danger"}
            >
              {word.done ? "Learned" : "Remaining"}
            </button>
          </div>
        </div>

        {this.state.isMeaningVisible && (
          <p>
            <b>Meaning</b>
          </p>
        )}
        {this.state.isMeaningVisible && <p>{this.camelCase(word.meaning)}</p>}
        {this.state.isMeaningVisible && (
          <p>
            <b>Example</b>
          </p>
        )}
        {this.state.isMeaningVisible && <p>{this.camelCase(word.example)}</p>}
      </div>
    );
  }
}
