import React, { Component } from "react";
import dataJSON from "./data.json";
import dataMixedJSON from "./dataMixed.json";
import uuid from "uuid/v4";
import Question from "./Question";
import ScrollUpButton from "react-scroll-up-button";
import ReactLoading from "react-loading";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import * as firebase from "firebase/app";

export default class App extends Component {
  state = {
    done: 0,
    left: 0,
    total: 0,
    doneMixed: 0,
    leftMixed: 0,
    totalMixed: 0,
    data: {},
    loading: true,
    isdataMixed: false,
    dataMixed: {},
  };
  componentDidMount() {
    let cacheData = localStorage.getItem("data");
    let cacheDataMixed = localStorage.getItem("dataMixed");
    if (cacheData && Object.keys(cacheData).length > 10) {
      this.setState({
        data: JSON.parse(cacheData),
        loading: false,
      });
    } else {
      localStorage.setItem("data", JSON.stringify(dataJSON));
      this.setState({
        data: dataJSON,
        loading: false,
      });
    }
    if (cacheDataMixed && Object.keys(cacheDataMixed).length > 10) {
      this.setState({
        dataMixed: JSON.parse(cacheDataMixed),
        loading: false,
      });
    } else {
      localStorage.setItem("dataMixed", JSON.stringify(dataMixedJSON));
      this.setState({
        dataMixed: dataMixedJSON,
        loading: false,
      });
    }
    this.getQuesCount();
    this.getMixedQuesCount();
    try {
      firebase.analytics().logEvent("page_view");
    } catch (e) {
      console.log(e);
    }
  }
  getMixedQuesCount = () => {
    let doneMixed = 0;
    let totalMixed = 0;
    let leftMixed = 0;
    let data = JSON.parse(localStorage.getItem("dataMixed"));
    Object.keys(data).forEach((key) => {
      let word = data[key];
      totalMixed = totalMixed + 1;
      if (word.done !== 0) {
        doneMixed = doneMixed + 1;
      } else {
        leftMixed = leftMixed + 1;
      }
    });
    this.setState({
      doneMixed,
      leftMixed,
      totalMixed,
    });
  };

  getQuesCount = () => {
    let done = 0;
    let total = 0;
    let left = 0;
    let data = JSON.parse(localStorage.getItem("data"));
    Object.keys(data).forEach((key) => {
      data[key].data.forEach((word) => {
        total = total + 1;
        if (word.done !== 0) {
          done = done + 1;
        } else {
          left = left + 1;
        }
      });
    });
    this.setState({
      done,
      left,
      total,
    });
  };
  setMarked = (key, bucket, word, index) => {
    this.setState({ loading: true });
    let data = JSON.parse(localStorage.getItem("data"));
    if (data[key].data[index].done === 0) {
      data[key].data[index].done = 1;
    } else {
      data[key].data[index].done = 0;
    }
    localStorage.setItem("data", JSON.stringify(data));
    this.setState({ data });
    this.setState({ loading: false });

    this.getQuesCount();
    try {
      firebase.analytics().logEvent("learnt_button_click");
    } catch (e) {
      console.log(e);
    }
  };
  setMarkedMixed = (key) => {
    this.setState({ loading: true });
    let data = JSON.parse(localStorage.getItem("dataMixed"));
    if (data[key].done === 0) {
      data[key].done = 1;
    } else {
      data[key].done = 0;
    }
    localStorage.setItem("dataMixed", JSON.stringify(data));
    this.setState({ dataMixed: data });
    this.setState({ loading: false });

    this.getMixedQuesCount();
    try {
      firebase.analytics().logEvent("learnt_button_click");
    } catch (e) {
      console.log(e);
    }
  };
  markAll = (key) => {
    let data = JSON.parse(localStorage.getItem("data"));

    data[key].data.forEach((word) => {
      if (word.done === 0) {
        word.done = 1;
      } else {
        word.done = 0;
      }
    });

    localStorage.setItem("data", JSON.stringify(data));
    this.getQuesCount();
    this.setState({ data });
  };
  randomSort = () => {
    this.setState({ loading: true });

    let data = JSON.parse(localStorage.getItem("data"));
    let auxArr = [];
    Object.keys(data).forEach((key) => {
      auxArr.push([
        { [" " + key]: data[key] },
        Math.floor(Math.random() * 100000),
      ]);
    });
    auxArr.sort((a, b) => a[1] - b[1]);
    let auxData = {};
    auxArr.forEach((el) => {
      auxData = { ...auxData, ...el[0] };
    });
    localStorage.setItem("data", JSON.stringify(auxData));
    this.setState({ data: auxData });
    this.setState({ loading: false });
    try {
      firebase.analytics().logEvent("sort");
    } catch (e) {
      console.log(e);
    }
  };
  randomSortMixed = () => {
    this.setState({ loading: true });

    let data = JSON.parse(localStorage.getItem("dataMixed"));
    let auxArr = [];
    Object.keys(data).forEach((key) => {
      auxArr.push([{ [key]: data[key] }, Math.floor(Math.random() * 100000)]);
    });
    auxArr.sort((a, b) => a[1] - b[1]);
    let auxData = {};
    auxArr.forEach((el) => {
      auxData = { ...auxData, ...el[0] };
    });
    localStorage.setItem("dataMixed", JSON.stringify(auxData));
    this.setState({ dataMixed: auxData });
    this.setState({ loading: false });
    try {
      firebase.analytics().logEvent("sort");
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    if (
      this.state.loading ||
      Object.keys(this.state.data).length < 10 ||
      Object.keys(this.state.dataMixed).length < 10
    ) {
      return (
        <ReactLoading
          type={"balls"}
          color={"#f9f9f9"}
          height={"100%"}
          width={"100%"}
        />
      );
    }
    if (this.state.isdataMixed) {
      return (
        <div className="container hero">
          <h1 className="text-center">Wordlist</h1>
          <h1 className="text-right">
            <button
              onClick={() => this.randomSortMixed()}
              className="btn btn-primary"
            >
              Random Sort
            </button>
            <button
              style={{
                marginLeft: 20,
              }}
              onClick={() => {
                const options = {
                  title: "Reset Progress?",
                  message:
                    "This will bring count of learned questions (MIXED) to 0",
                  buttons: [
                    {
                      label: "Yes",
                      onClick: () => {
                        localStorage.removeItem("dataMixed");
                        window.location.reload();
                      },
                    },
                    {
                      label: "No",
                      onClick: () => {},
                    },
                  ],
                };

                confirmAlert(options);
                try {
                  firebase.analytics().logEvent("reset");
                } catch (e) {
                  console.log(e);
                }
              }}
              className="btn btn-light"
            >
              Reset
            </button>
            <button
              style={{
                marginLeft: 20,
              }}
              onClick={() => {
                this.setState((prevState) => ({
                  isdataMixed: !prevState.isdataMixed,
                }));
                try {
                  firebase.analytics().logEvent("toggle_clicked");
                } catch (e) {
                  console.log(e);
                }
              }}
              className="btn btn-light"
            >
              Toggle Mixed
            </button>
          </h1>
          <h5>{`${this.state.doneMixed}/${this.state.totalMixed}`}</h5>
          {Object.keys(this.state.dataMixed).map((key) => {
            let word = this.state.dataMixed[key];
            return (
              <Question
                setMarked={() => this.setMarkedMixed(key)}
                word={word}
              />
            );
          })}
          <ScrollUpButton />
        </div>
      );
    }
    return (
      <div className="container hero">
        <h1 className="text-center">Wordlist</h1>
        <h1 className="text-right">
          <button onClick={() => this.randomSort()} className="btn btn-primary">
            Random Sort
          </button>
          <button
            style={{
              marginLeft: 20,
            }}
            onClick={() => {
              const options = {
                title: "Reset Progress?",
                message:
                  "This will bring count of learned questions (BUCKETED) to 0",
                buttons: [
                  {
                    label: "Yes",
                    onClick: () => {
                      localStorage.removeItem("data");
                      window.location.reload();
                    },
                  },
                  {
                    label: "No",
                    onClick: () => {},
                  },
                ],
              };

              confirmAlert(options);
              try {
                firebase.analytics().logEvent("reset");
              } catch (e) {
                console.log(e);
              }
            }}
            className="btn btn-light"
          >
            Reset
          </button>
          <button
            style={{
              marginLeft: 20,
            }}
            onClick={() => {
              this.setState((prevState) => ({
                isdataMixed: !prevState.isdataMixed,
              }));
              try {
                firebase.analytics().logEvent("toggle_clicked");
              } catch (e) {
                console.log(e);
              }
            }}
            className="btn btn-light"
          >
            Toggle Mixed
          </button>
        </h1>
        <h5>{`${this.state.done}/${this.state.total}`}</h5>
        {Object.keys(this.state.data).map((key) => {
          let bucket = this.state.data[key].data;
          return (
            <div className="bucket" key={uuid()}>
              <h1 className="text-right">
                <button
                  onClick={() => {
                    this.markAll(key);
                  }}
                  className="btn btn-light"
                >
                  Mark / Un-Mark All in this bucket
                </button>
              </h1>
              {bucket.map((word, index) => {
                return (
                  <Question
                    setMarked={() => this.setMarked(key, bucket, word, index)}
                    word={word}
                  />
                );
              })}
            </div>
          );
        })}
        <ScrollUpButton />
      </div>
    );
  }
}
